const state = () => ({
    loggedIn: false,
    loggedInUser: {},
    settings: []
});

const getters = {
    loggedIn(state) {
        return state.loggedIn;
    },
    settings(state) {
        return state.settings;
    },
    token(state) {
        return state.loggedInUser.token;
    },
    daysNightsText() {
        return this.settings.daysNights.charAt(0).toUpperCase() + this.settings.daysNights.slice(1);
    },
};

const actions = {
    async setLogin({ commit }, payload) {
        commit('setLoggedIn', true);
        commit('setLoggedInUser', payload);
    },
    async setSettings({ commit }, payload) {
        commit('setStateSettings', payload);
    },
    async disableLogin({ commit }) {
        commit('setLoggedIn', false);
    }
};

const mutations = {
    setLoggedIn(state, payload) {
        state.loggedIn = payload;
    },

    setLoggedInUser(state, payload) {
        state.loggedInUser = payload.data;
    },

    setStateSettings(state, payload) {
        state.settings = payload;
    }
};


export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}