<template>
    <ion-page>
        <keep-alive>
            <ion-tabs>
                <ion-router-outlet></ion-router-outlet>
                <ion-tab-bar slot="top">
                    <div v-if="this.settings.systemBrand === 'TPS'">
                        <img src="/img/tps.png"
                             style="display: block; width: 75px; height: auto; margin-left: auto; margin-right: 2%;"/>
                    </div>
                    <div v-if="this.settings.systemBrand === '3D'">
                        <img src="/img/3dparking.png"
                             style="display: block; width: 75px; height: auto; margin-left: auto; margin-right: 2%;"/>
                    </div>
                    <ion-tab-button tab="home" href="/tabs/home">
                        <ion-icon :icon="arrowBackCircleOutline" />
                    </ion-tab-button>
                    <ion-tab-button tab="settings" href="/tabs/login">
                        <ion-icon :icon="cogOutline" />
                    </ion-tab-button>
                </ion-tab-bar>
            </ion-tabs>
        </keep-alive>
    </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { arrowBackCircleOutline, cogOutline } from 'ionicons/icons';
import { mapGetters } from "vuex";

export default {
    name: 'Tabs',
    components: { IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
    data() {
        return {

        }
    },
    setup() {
        return {
            arrowBackCircleOutline,
            cogOutline,
        }
    },
    computed: {
        ...mapGetters('users', {
            settings: 'settings'
        })
    }
}
</script>