<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapGetters } from "vuex";
import {api} from "@/main";


export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet
    },
    beforeMount() {
        if (this.settings.length === 0) {
            this.$router.push('/tabs/login');
        }
    },
    mounted() {
        /*this.checkSession().then(() => {
            setTimeout(() => {
                this.checkSession();
            }, 120000);
        })*/
    },
    methods: {
        async checkSession() {
            api.get('/api/check-session', {
                headers: {
                    Authorization: 'Bearer: ' + this.token
                }
            }).then((response) => {
                if (response.status === 200) {
                    setTimeout(() => {
                        this.checkSession()
                    }, 120000);
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.notInUse(error.response.data);
                } else if (error.response.status === 400) {
                    this.$router.push('/tabs/login');
                }
            });
        }
    },
    computed: {
        ...mapGetters('users', {
            settings: 'settings'
        })
    }
});
</script>

<style scoped>
    ion-app {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("/img/logo.png");
        background-size: cover;
    }
</style>