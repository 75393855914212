import { createStore } from 'vuex';
import UserModule from './modules/users';
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    modules: {
        users: UserModule
    },
    plugins: [
        createPersistedState()
    ]
});

export default store;